export function groupBy<T>(source: T[], selector: (item: T) => number | string): { [key: string]: T[] } {
  const result: { [key: string]: T[] } = {};
  for (const item of source) {
    const key = selector(item);
    if (result[key] === undefined) {
      result[key] = [];
    }
    result[key].push(item);
  }
  return result;
}

export type GroupedByMany<T> = { keys: (string | number)[], values: T[] }[];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function groupByMany<T = any>(source: T[], ...selectors: ((item: T) => number | string)[]): GroupedByMany<T> {
  const initialGrouping: GroupedByMany<T> = [{
    keys: [],
    values: source
  }];
  return selectors.reduce((grouping, selector) => {
    return grouping.map(group => {
      return Object.entries(groupBy(group.values, selector)).map(([key, values]) => ({
        keys: [...group.keys, key],
        values
      }));
    }).reduce((all, batch) => all.concat(batch), []);
  }, initialGrouping);
}
